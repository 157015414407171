<template>
  <div>
    <b-carousel-list
        id="browse-carousel"
        :data="items"
        :items-to-show="itemsToShow"
        :arrow="arrow"
        :items-to-list="increment"
        :repeat="repeat"
        :has-drag="drag"
    >
      <template slot="item" slot-scope="list">
        <div class="card carousel-card">
          <BrowseCarouselItem :item="items[list.index]"></BrowseCarouselItem>
        </div>
      </template>
    </b-carousel-list>

  </div>
</template>

<script>
import BrowseCarouselItem from './BrowseCarouselItem';


export default {
  name: "BrowseCarousel",
  components: {
    BrowseCarouselItem,
  },
  data(){
    return{
      itemsToShow: 2,
      arrow: true,
      arrowHover: true,
      drag: true,
      gray: false,
      opacity: false,
      values: 1,
      increment: 2,
      repeat: false,
      items: [
        {
          title: 'Day trading Ted',
          image: 'https://investortalk.interadhost.eu/img/sl1.png'
        },
        {
          title: 'Win Win Daily',
          image: 'https://investortalk.interadhost.eu/img/sl1.png'
        },
        {
          title: 'Zowi Line Tips',
          image: 'https://investortalk.interadhost.eu/img/sl1.png'
        },
        {
          title: 'Mike Line Tips',
          image: 'https://investortalk.interadhost.eu/img/sl1.png'
        },
        {
          title: 'Top Tips',
          image: 'https://investortalk.interadhost.eu/img/sl1.png'
        },
      ]
    }
  },
  mounted() {
    this.setCarouselCols();
    this.$nextTick(()=>{
      window.addEventListener('resize', this.setCarouselCols);
    });
  },
  methods: {
    setCarouselCols() {
      if (window.innerWidth > 1440) {
        this.itemsToShow = 3;
      } else if (window.innerWidth <= 992) {
        this.itemsToShow = 1;
      } else {
        this.itemsToShow = 2;
      }
    }

  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400&display=swap');

.quicksand-light {
  font-family: 'Quicksand', sans-serif;
}

#browse-carousel {
  width: 100%;
}

#browse-carousel .carousel-card {
  background-color: transparent !important;

}



#browse-carousel .carousel-arrow .icon {
  background-color: rgba(104, 67, 255, 0.77) !important;
  color: #ffffff !important;
  cursor: pointer;
  border: 1px solid transparent;
  width: 45px;
  height: 45px;
  padding: 9px 14px;
  /**outline: 0;**/
}


#browse-carousel .carousel-arrow .icon.has-icons-right {
  right: 2.5rem;
}
</style>
