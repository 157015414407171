<template>
  <b-field>
    <b-select v-model="selectedCategory">
    <!--option value="all" selected>all</option-->
      <option v-for="(cat , i) in categories"
        :key="'cat-'+i"
        :value=cat
      >{{cat.name}}</option>
    </b-select>
  </b-field>
</template>

<script>
  export default {
    name: "BrowseCategoriesNew",
    props:['categories', 'selectedCat'],
    data(){
      return{
        //selectedCategory: (this.selectedCat) ? this.selectedCat : 'all',
        selectedCategory: null,
      }
    },
    computed:{},
    watch:{
      selectedCategory(newValue){
        if (newValue) {
          //console.log('selectedCategory child', newValue);
          //this.onCategory(newValue);
          this.$emit('changedCategory', newValue);
        }
      },
      selectedCat(newValue) {
        //console.log('selectedCat child', newValue);
        this.selectedCategory = newValue;
      }

    },
    methods:{
      onCategory(cat) {
        //console.log('onCategory',cat);

        //this.$store.commit('set_selectedCategory', cat);
      }
    },
    mounted() {
      //console.log('######', this.selectedCategory);
    },
    created() {
      //this.selectedCategory = this.selectedCat;
    }

  }
</script>

<style scoped>

</style>
