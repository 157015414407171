<template>
  <div class="browse-carousel-item" :style="{ 'background-image': 'url(' + item.image + ')' }">
    <div class="browse-one">
        <div class="browse-one-top">
          <div class="browse-one-top-left"
               :style="{ 'background-image': 'url(' + smimg + ')' }"
                >
            <!--img src="../../../public/img/smimg.png" alt="slider_images"-->
          </div>
          <div class="browse-one-top-right">
            <p>{{item.title}}</p>
            <p style="font-family: 'Circular Std Book';font-weight: 450;">17,857 members</p>
          </div>
        </div>
        <div class="browseoh-buttons">
          <!--ul-->
            <a href="#">stock</a>
            <a href="#">day trading</a>
            <a href="#">lifestyle</a>
          <!--</ul>-->
        </div>
        <div class="browseoh-text">
        Super cool adventures of day trading lifestyle. Join and explore different strats and daily tips.
        Let’s get it. In this channel we can discuss everything regarding leads consectetur adipiscing elit.
        Maecenas varius tortor nibh, sit amet tempor nibh finibus et.</div>
    </div>

  </div>
</template>

<script>
    export default {
      name: "BrowseCarouselItem",
      props:{
        item: null
      },
      data(){
        return{

        }
      },
      computed: {
        leftStyle() {
          return "background: url(" + this.item.image + ")";
        },
        smimg () {
          return require('@/assets/img/smimg.png');
        },
      },

    }
</script>

<style scoped>
.card {
    background-color: black !important;
}

.browse-carousel-item {
  height: 245px;
  background-position: center;
  background-size: cover;
}

.browse-one {
  width: 100%;
  max-width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: rgba(33, 12, 117, 0.88);
}

.browse-one-top {
  margin-top: 40px;
  margin-left: 50px;
  margin-right: 50px;
  display: flex;
}


.browse-one-top-left {
  display: inline-block;
  width: 35px;
  height: 35px;
  background-position: center;
  background-size: cover;
  margin-top: 1px;
}

.browse-one-top-left img {
  width: 35px;
  height: 35px;
}

.browse-one-top-right {
  display: inline-block;
  margin-left: 11px;
  line-height: 1.2;
}

.browse-one-top-right p {
  color: #ffffff;
  font-weight: normal;
}

.browseoh-text {
  /*height: 125px;*/
  margin: 17px 50px;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.34px;
  color: var(--white);
  overflow: hidden;
  display: flex;
  font-family: 'Quicksand', sans-serif;
}

.browseoh-buttons {
  display: flex;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 3px;
}

.browseoh-buttons a {
  display: inline-block;
  height: auto;
  /*margin: 10px 2px 17px;*/
  margin-right: 7px;
  margin-top: 6px;
  padding: 4px 10px 4px 10px;
  font-size: 12px;
  font-weight: bold;
  width: auto;
  color: #ffffff;
  letter-spacing: 0px;
  border-radius: 26px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background: linear-gradient( 209deg, #a083fa 137%, #674cf4 26%);
}

</style>
