<template>
  <vueScroll @handle-scroll="handleScroll" @handle-scroll-complete="handleScrollCompleted" id="browse" ref="browseScroll" :ops="ops" class="main-scroll">
    <resize-observer @notify="handleResize"/>
    <div id="browse-slider">
      <BrowseCarousel v-if="showCarousel"></BrowseCarousel>
    </div>
    <div id="browse-filters" ref="mainLive">
      <div id="browse-heading"><h3>Explore <small style="color: #5c5c5c;">Servers</small></h3></div>
      <div id="browse-actions">
        <div id="browse-broadcast" class="browse-action">
            <b-checkbox
                v-model="checkbox"
                @input="setCheckbox"
                true-value="Broadcasting "
                false-value="Broadcasting"
            >{{checkbox}}</b-checkbox>
        </div>
        <div id="browse-categories-select" class="browse-action">
          <div class="dropdown-label field"><span>Categories:</span></div>
          <BrowseCategoriesNew v-if="categories"
                               :categories="categories"
                               :selectedCat="selectedCat"
                               v-on:changedCategory="changedCategory"
          ></BrowseCategoriesNew>
        </div>
        <div id="browse-sort" class="browse-action">
          <!--div class="display-tables"></div-->
          <b-button id="browse-sorting">
            <fa-icon v-if="sortingAsc" @click="sortingAsc = false" icon="sort-amount-up" :size="$faDefaultIconSize"/>
            <fa-icon v-if="!sortingAsc" @click="sortingAsc = true" icon="sort-amount-down" :size="$faDefaultIconSize"/>
          </b-button>
          <div class="dropdown-label field"><span>Sort by:</span></div>
          <b-field>
            <b-select v-model="selectedOption">
              <option value="famous" selected>Popularity</option>
              <option value="dateCreated">Date Created</option>

            </b-select>
          </b-field>
        </div>
        <div id="browse-search"  class="browse-action">
          <b-field>
            <b-input
                placeholder="Search..."
                type="search"
                v-model="text"
                icon="search"
                icon-right="times"
                icon-right-clickable
                @icon-right-click="clearSearchBar"
            />
          </b-field>
        </div>
      </div>
      <!--b-button  @click="showCategories = !showCategories" size="is-small"
                 style="background-color: transparent; border-color: transparent;"
      ><fa-icon icon="sliders-h" class="navbar-button-icon" :size="$faDefaultIconSize" /></b-button-->
    </div>

    <div id="browse-list" ref="browseList" class="columns" v-if="servers && servers.length" :class="{'has-carousel': showCarousel}">
      <div style="height: 100%; max-height: 100%; width: 100%; overflow: hidden">
        <!--vuescroll id="browse-list-scroll"  :ops="ops" -->
        <BrowseServersChild
            class="browse-single column"
            :style="{ 'width': itemCol+'%', 'max-width': itemCol+'%' }"
            v-for="(server,i) in servers"
            :key="i"
            :server="server"
        ></BrowseServersChild>
        <!--        <p v-if="showLoadMore " id="browse-load-more" ref="loadMore">-->
        <!--          <b-button @click="handleLoadMore" :loading="loading">Load More</b-button>-->
        <!--        </p>-->
        <!--        <p v-if="showLoadMoreQuery" id="browse-load-more-query" ref="loadMoreQuery">-->
        <!--          <b-button @click="handleLoadMoreQuery" :loading="loading">Load More</b-button>-->
        <!--        </p>-->
        <p v-if="loading" id="browse-load-more">
          <b-button :loading="loading">Load More</b-button>
        </p>
        <!--/vuescroll-->
      </div>
    </div>
  </vueScroll>
</template>

<script>

import BrowseServersChild from "../components/BrowseCore/BrowseServersChild";
//import BrowseCategories from "../components/BrowseCore/BrowseCategories";
import BrowseCarousel from "@/components/BrowseCore/BrowseCarousel";
// import BrowseSearch from "@/components/BrowseCore/BrowseSearch";
import vueScroll from "vuescroll";
import axios from "@/axios";
import { ResizeObserver } from 'vue-resize';
import 'vue-resize/dist/vue-resize.css'
import BrowseCategoriesNew from '../components/BrowseCore/BrowseCategoriesNew';
import { debounce } from 'lodash';

export default {
  name: "Browse",
  components: {
    BrowseCarousel,
    vueScroll,
    BrowseServersChild,
    BrowseCategoriesNew,
    'resize-observer': ResizeObserver,
    // BrowseSearch,
    //BrowseCategoriesNew
  },
  data() {
    return {
      checkbox: 'Broadcasting',
      booleanCheckbox: false,
      loading: false,
      sortingAsc: false,
      enableScroll: false,
      browseMain: null,
      browseWidth: null,
      showCategories: false,
      categories: [],
      selectedCat: null,
      //itemSize: '25%',
      showCarousel: true,
      itemCol: 33,
      nextLoad: 4,
      columns: 4,
      startCursor: new Date('December 31, 2018 03:24:00'),
      skipFamous: null,
      nextCursorOldest: null,
      nextCursorLatest: null,
      showLoadMore: true,
      servers: [],
      selectedOption: 'famous',
      flag1: 1,
      flag2: 2,
      ops: {
        vuescroll: {
          mode: 'native',
          sizeStrategy: 'percent',
          detectResize: false,
          /** Enable locking to the main axis if user moves only slightly on one of them at start */
          locking: false,
        },
        scrollPanel: {
          initialScrollY: '0%',
          scrollingX: false,
          scrollingY: true,
          speed: 200,
          //easing: undefined,
          //verticalNativeBarPos: 'left'
        },
        rail: {},
        bar: {},
      },
      text: '',
      query: '',
      skipFamousQuery: null,
      nextCursorOldestQuery: null,
      nextCursorLatestQuery: null,
      showLoadMoreQuery: false,
      scrollAreaHeight: null,
      scrollArea: null,
      lastScrollTop: 0,
      lockLoadMore: false
    }
  },
  async created() {
    if (this.$route.params && this.$route.params.category) {
      await this.fetchCategories(false);
      console.log('route.category: ', this.$route.params.category);
      this.selectedCat = this.$route.params.category;
    } else {
      this.fetchCategories();
    }
  },
  mounted() {
    this.$store.dispatch('set_openCloseSidebar', 'close');
    this.scrollArea = document.querySelector('#browse .__view');
    //this.setBrowseWidth();
  },
  watch: {
    checkbox(newVal) {
      this.booleanCheckbox = newVal !== 'Broadcasting';
      this.flag1 = 1;
      this.flag2 = 2;
      this.showLoadMore = true;
      this.skipFamous = 0;
      this.nextCursorOldest = this.startCursor;
      this.nextCursorLatest = this.startCursor;
      this.skipFamousQuery = 0;
      this.nextCursorOldestQuery = this.startCursor;
      this.nextCursorLatestQuery = this.startCursor;
      this.servers = [];
      this.enableScroll = false;
      if (this.query) {
        this.getAsyncData(this.query);
      } else {
        this.fetchServers();
      }
    },
    // servers(newVal) {
    //   if (this.enableScroll) {
    //     this.scrollToLast();
    //   }
    // },
    sortingAsc(newValue) {
      this.flag1 = 1;
      this.flag2 = 2;
      this.showLoadMore = true;
      this.skipFamous = 0;
      this.nextCursorOldest = this.startCursor;
      this.nextCursorLatest = this.startCursor;
      this.skipFamousQuery = 0;
      this.nextCursorOldestQuery = this.startCursor;
      this.nextCursorLatestQuery = this.startCursor;
      this.servers = [];
      this.enableScroll = false;
      if (this.query) {
        this.getAsyncData(this.query);
      } else {
        this.fetchServers();
      }
    },
    async selectedCat(newVal, oldVal) {
      if (newVal) {
        this.flag1 = 1;
        this.flag2 = 2;
        this.showLoadMore = true;
        this.skipFamous = 0;
        this.nextCursorOldest = this.startCursor;
        this.nextCursorLatest = this.startCursor;
        this.skipFamousQuery = 0;
        this.nextCursorOldestQuery = this.startCursor;
        this.nextCursorLatestQuery = this.startCursor;
        this.servers = [];
        this.enableScroll = false;
        if (this.query) {
          this.getAsyncData(this.query);
        } else {
          this.fetchServers();
        }
      }
    },
    selectedOption(newValue) {
      if (newValue) {
        this.flag1 = 1;
        this.flag2 = 2;
        this.showLoadMore = true;
        this.skipFamous = 0;
        this.nextCursorOldest = this.startCursor;
        this.nextCursorLatest = this.startCursor;
        this.skipFamousQuery = 0;
        this.nextCursorOldestQuery = this.startCursor;
        this.nextCursorLatestQuery = this.startCursor;
        this.servers = [];
        this.enableScroll = false;
        if (this.query) {
          this.getAsyncData(this.query);
        } else {
          this.fetchServers();
        }
      }
    },
    browseWidth(newval, oldval) {
      //console.log('browseWidth', newval);
      if (newval > 1600) {
        this.columns = 4;
      } else if (newval <= 992 && newval > 530) {
        this.columns = 2;
      } else if (newval <= 530) {
        this.columns = 1;
      } else {
        this.columns = 3;
      }
      this.itemCol = parseFloat((100 / this.columns) - 0.5);
    },
    text(val) {
      if (val.length === 0) {
        if (this.skipFamousQuery !== null) {
          this.clearSearchBar();
        }
        this.showLoadMoreQuery = false;
      } else {
        this.getAsyncData(val);
      }
    },
  },
  methods: {
    setCheckbox(e){

      console.log('cb', e);
    },
    handleScroll(vertical, horizontal, nativeEvent) {
      this.scrollAreaHeight = this.scrollArea.clientHeight;
      const scr = this.scrollArea.clientHeight - this.$refs.browseScroll.$el.clientHeight;

      if (vertical.scrollTop < this.lastScrollTop) {
        console.log('upwards');
        this.lastScrollTop = vertical.scrollTop;
        return false;
      }
      console.log('downwards', vertical.scrollTop, scr);
      if (vertical.scrollTop >= scr  && !this.lockLoadMore) {
        console.log('scr');
        this.lastScrollTop = vertical.scrollTop;
        // this.enableScroll = false;
        setTimeout(()=> {
          if(this.showLoadMoreQuery) {
            this.handleLoadMoreQuery();
          } else if (this.showLoadMore) {
            this.handleLoadMore();
          }
          // this.fetchServers();
        }, 250);
      } else {
        this.lastScrollTop = vertical.scrollTop;
      }
    },
    handleScrollCompleted() {
      console.log('handleScrollCompleted');
      this.lockLoadMore = false;
    },
    scrollToLast() {
      this.lockLoadMore = true;
      let elems = this.$el.getElementsByClassName("browse-one");
      if (elems.length) {
        const last = elems[elems.length - 1];
        this.$refs["browseScroll"].scrollIntoView(last, true);
        //last.scrollIntoView({behavior: "auto", block: "center", inline: "nearest"});
        //this.$parent.scrollLock = false;
        console.log('scrol2last1 end' );
        // EventBus.$emit('NEW_ROOM_LOADING', false);
      }
    },
    handleResize({width, height}) {
      //console.log('handleResize', width);
      this.browseWidth = width;
    },
    setBrowseWidth() {
      let main = document.getElementById('browse');
      if (main) {
        //console.log('main.offsetWidth', main.offsetWidth)
        this.browseWidth = main.offsetWidth;
      } else {
        this.browseWidth = 900;
      }
    },
    handleLoadMore() {
      console.log('handleLoadMore');
      this.enableScroll = true;
      this.fetchServers();
    },
    handleLoadMoreQuery() {
      console.log('handleLoadMoreQuery');
      this.enableScroll = true;
      this.getAsyncData(this.query);
    },
    async fetchServers() {
      this.loading = true;
      let url;
      let sorting = (this.sortingAsc) ? 'asc' : 'desc';
      console.log('sorting inside fetchServers ', sorting);
      //console.log('selectedOption inside fetchServers',this.selectedOption)
      if (this.selectedOption === 'famous') {
        url = `/servers/famous/${sorting}/${this.booleanCheckbox}/${this.columns * 2}/${this.skipFamous}/`;
      } else if (sorting === 'asc') {
        url = `/servers/${sorting}/${this.booleanCheckbox}/${this.columns * 2}/${this.nextCursorLatest}/`;
      } else {
        url = (this.flag1 === 1)
            ? `/servers/${sorting}/${this.booleanCheckbox}/${this.columns * 2}/Mon Dec 31 3000 03:24:00 GMT+0200 (Eastern European Standard Time)/`
            : `/servers/${sorting}/${this.booleanCheckbox}/${this.columns * 2}/${this.nextCursorOldest}/`;
      }

      if (this.selectedCat._id) {
        if (this.selectedOption === 'famous') {
          url = `/servers/category/id/famous/${this.selectedCat._id}/${sorting}/${this.booleanCheckbox}/${this.columns * 2}/${this.skipFamous}/`;
        } else if (sorting === 'asc') {
          url = `/servers/category/id/${this.selectedCat._id}/${sorting}/${this.booleanCheckbox}/${this.columns * 2}/${this.nextCursorLatest}/`;
        } else {
          console.log('this.flag2', this.flag2);
          url = (this.flag2 === 2) ?
              `/servers/category/id/${this.selectedCat._id}/${sorting}/${this.booleanCheckbox}/${this.columns * 2}/Mon Dec 31 3000 03:24:00 GMT+0200 (Eastern European Standard Time)/`
              : `/servers/category/id/${this.selectedCat._id}/${sorting}/${this.booleanCheckbox}/${this.columns * 2}/${this.nextCursorOldest}/`;
        }

      }

      if (url === `/servers/${sorting}/${this.booleanCheckbox}/${this.columns * 2}/Mon Dec 31 3000 03:24:00 GMT+0200 (Eastern European Standard Time)/`) {
        this.flag1 = 3;
      }

      if (url === `/servers/category/id/${this.selectedCat._id}/${sorting}/${this.booleanCheckbox}/${this.columns * 2}/Mon Dec 31 3000 03:24:00 GMT+0200 (Eastern European Standard Time)/`) {
        this.flag2 = 4;
      }
      console.log('url', url);
      try {
        const {data} = await axios.get(url);
        console.log('response data', data);
        this.loading = false;
        if (this.selectedOption === 'famous') {
          this.skipFamous += data.limit;
        } else if (sorting === 'asc') {
          this.nextCursorLatest = data.nextCursor;
        } else {
          this.nextCursorOldest = data.nextCursor;
        }

        if (data.servers) {
          this.servers.push(...data.servers);
        } else {
          this.servers.push(...data);
        }
        //this.setBrowseWidth();
        if (data.servers.length < this.columns * 2) {
          this.showLoadMore = false;
          this.enableScroll = false;
        } else {
          this.showLoadMore = true;
        }
      } catch (e) {
        console.log(e);
        this.loading = false;
      }

      this.enableScroll = true;
    },
    getAsyncData: debounce(function (query) {
      this.showLoadMore = false;
      // String update
      if (this.query !== query) {
        this.query = query
        this.servers = []
        this.skipFamousQuery = null;
        this.nextCursorLatestQuery = null;
        this.nextCursorOldestQuery = null;
      }
      // String cleared
      if (!query.length) {
        this.servers = []
        return
      }

      this.loading = true;
      let url;
      let sorting = (this.sortingAsc) ? 'asc' : 'desc';
      console.log('sorting inside getAsyncData ', sorting);
      //console.log('selectedOption inside getAsyncData',this.selectedOption)
      if (this.selectedOption === 'famous') {
        url = `/servers/famous/${sorting}/${this.booleanCheckbox}/${this.columns * 2}/${query}/${this.skipFamousQuery}/`;
      } else if (sorting === 'asc') {
        url = `/servers/${sorting}/${this.booleanCheckbox}/${this.columns * 2}/${query}/${this.nextCursorLatestQuery}/`;
      } else {
        url = (this.flag1 === 1)
            ? `/servers/${sorting}/${this.booleanCheckbox}/${this.columns * 2}/${query}/Mon Dec 31 3000 03:24:00 GMT+0200 (Eastern European Standard Time)/`
            : `/servers/${sorting}/${this.booleanCheckbox}/${this.columns * 2}/${query}/${this.nextCursorOldestQuery}/`;
      }

      if (this.selectedCat._id) {
        if (this.selectedOption === 'famous') {
          url = `/servers/category/id/famous/${this.selectedCat._id}/${sorting}/${this.booleanCheckbox}/${this.columns * 2}/${query}/${this.skipFamousQuery}/`;
        } else if (sorting === 'asc') {
          url = `/servers/category/id/${this.selectedCat._id}/${sorting}/${this.booleanCheckbox}/${this.columns * 2}/${query}/${this.nextCursorLatestQuery}/`;
        } else {
          console.log('this.flag2', this.flag2);
          url = (this.flag2 === 2) ?
              `/servers/category/id/${this.selectedCat._id}/${sorting}/${this.booleanCheckbox}/${this.columns * 2}/${query}/Mon Dec 31 3000 03:24:00 GMT+0200 (Eastern European Standard Time)/`
              : `/servers/category/id/${this.selectedCat._id}/${sorting}/${this.booleanCheckbox}/${this.columns * 2}/${query}/${this.nextCursorOldestQuery}/`;
        }
      }

      if (url === `/servers/${sorting}/${this.booleanCheckbox}/${this.columns * 2}/${query}/Mon Dec 31 3000 03:24:00 GMT+0200 (Eastern European Standard Time)/`) {
        this.flag1 = 3;
      }

      if (url === `/servers/category/id/${this.selectedCat._id}/${sorting}/${this.booleanCheckbox}/${this.columns * 2}/${query}/Mon Dec 31 3000 03:24:00 GMT+0200 (Eastern European Standard Time)/`) {
        this.flag2 = 4;
      }
      console.log('url', url);
      try {
        axios.get(url)
            .then(({data}) => {
              console.log('response data', data);
              this.loading = false;
              if (this.selectedOption === 'famous') {
                this.skipFamousQuery += data.limit;
              } else if (sorting === 'asc') {
                this.nextCursorLatestQuery = data.nextCursor;
              } else {
                this.nextCursorOldestQuery = data.nextCursor;
              }

              if (data.servers) {
                this.servers.push(...data.servers);
              } else {
                this.servers.push(...data);
              }
              //this.setBrowseWidth();
              if (data.servers.length < this.columns * 2) {
                this.showLoadMoreQuery = false;
              } else {
                this.showLoadMoreQuery = true;
              }
            });
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    }, 500),
    clearSearchBar() {
      this.text = '';
      this.query = '';
      this.skipFamousQuery = null;
      this.nextCursorLatestQuery = null;
      this.nextCursorOldestQuery = null;
      this.skipFamous = null;
      this.nextCursorLatest = null;
      this.nextCursorOldest = null;
      this.servers = [];
      this.fetchServers();
    },
    async fetchCategories(isSelectedCat = true) {
      console.log('isSelectedCat', isSelectedCat);
      try {
        const {data} = await axios.get('/categories/');
        const catAll = {
          //_id: 'all', // No id needed
          name: 'All Categories',
          slug: 'all',
          image: ''
        }

        let cats = data;
        cats.unshift(catAll);
        //cats.push(response.data);
        this.categories = cats;
        console.log('fetchCategories', this.categories[0]);
        //this.$store.commit('set_selectedCategory','all');
        if (isSelectedCat)
          this.selectedCat = this.categories[0];
      } catch (e) {
        console.log('fetchCategories ERROR  ', e);
      }
    },
    changedCategory(cat) {
      console.log('changedCategory parent', cat);
      this.selectedCat = cat;
    },
    scrollToLastOne() {
      let elems = this.$el.getElementsByClassName("browse-single");
      this.lockLoadMore = true;
      this.$nextTick(() => {
        if (elems.length > 0) {
          elems[elems.length - 1].scrollIntoView({behavior: "smooth", block: "start", inline: "center"});
        }
      });
    },
  }
}

</script>

<style>
.main-scroll {
  overflow: auto;
  height: 100%;
  max-height: 100%;
}

#browse {
  /**display: block;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  position: relative;
  margin: 0;
  display: inline-flex;*/
  /*display: block;*/
}

/*#browse .__panel {*/
/*  height: 100%;*/
/*  max-height: 100%;*/

/*}*/

/*#browse .__view {*/
/*  height: 100%;*/
/*  max-height: 100%;*/
/*}*/

#browse-categories {
  height: 100%;
  max-height: 100%;
  display: inline-block;
  background-color: #23272a;
  overflow: hidden;
  padding: 0.5em 0;
  width: 255px; /** zeplin */
  max-width: 255px;
}


#browse-main {
  height: 100%;
  max-height: 100%;
  display: block;
  position: relative;
  /**padding: 0.75em 0.75em 0.75em;*/
  padding: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
  background-color: #1d2023;
  width: calc(100% - 1px);
  max-width: calc(100% - 1px);
}

#browse-main.has-categories {
  width: calc(100% - 256px);
  max-width: calc(100% - 256px);
}

#browse-slider {
  padding: 55px 0;
  display: none;
}

#browse-filters {
  padding: 30px;
  display: flex;
  align-items: center;
}




#browse-overlay {
  height: 100%;
  max-height: 100%;
  display: inline-block;
  position: relative;
  padding: 0.75em 0.75em 1.25em;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
  background-color: #1d2023;
  width: 100%;
}

#browse-sorting {
  background-color: transparent;
  border-color: transparent;
  padding-left: 0.75em;
  padding-right: 0.75em;
  padding-top: 0.5em;

}

#browse-categories-select {
  /*display: inline-block;*/
  /*margin: auto 1px;*/
}

#browse-categories-select .select select,
#browse-categories-select .select select:active,
#browse-categories-select .select select:visited,
#browse-categories-select .select select:hover {
  border-color: transparent;
  -webkit-box-shadow: 0 0 0 0.125em rgba(121, 87, 213, 0);
  box-shadow: 0 0 0 0.125em rgba(121, 87, 213, 0);
}

/**
#browse-categories-select .field {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
}
 */

#browse-broadcast {
  /*padding-top: 10px;*/
  /*padding-right: 15px;*/
  /*margin: auto 1px;*/
  /*font-weight: inherit;*/
  /*font-style: inherit;*/
  /*font-size: 14px;*/
  margin-right: 15px;
  color: #cccbcb;
  font-size: 14px;
  font-weight: 600;
}

#browse-broadcast .control-label{
  color: #cccbcb;
  font-size: 14px;
  font-weight: 600;
}

#browse-sort {
  /*display: inline-block;*/
  /*padding-top: 14px;*/
}

#browse-categories-select .field,
#browse-sort .field {
  /*display: inline-block;*/
  display: flex;
  align-items: center;
  /*vertical-align: middle;*/
  margin: 0;

  margin-top: 0 !important;

}

#browse-categories-select select,
#browse-sort select {
  background-color: transparent;
  border-color: transparent;
  border-radius: 4px;
  color: #664bf3;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  padding-right: 1.5em;
  height: 100%;
  /*display: table;*/
  padding-bottom: 9px;

}


#browse-sort .select select,
#browse-sort .select select:active,
#browse-sort .select select:visited,
#browse-sort .select select:hover {
  border-color: transparent;
  -webkit-box-shadow: 0 0 0 0.125em rgba(121, 87, 213, 0);
  box-shadow: 0 0 0 0.125em rgba(121, 87, 213, 0);
}


#browse-categories-select .select select option,
#browse-sort .select select option {
  padding: calc(0.75em - 1px) calc(0.75em - 1px);
  display: table-caption;
  font-weight: bold;
  background-color: #23272a;
  /*font-family: Circular, 'sans-serif ';*/
}

#browse-categories-select .select select option:hover,
#browse-sort .select select option:hover {
  background-color: #303538 !important;
  color: #fff;
}

#browse-categories-select .select select option span,
#browse-sort .select select option span {
  /*display: table-cell;*/
}


#browse-categories-select .select:not(.is-multiple):not(.is-loading)::after,
#browse-sort .select:not(.is-multiple):not(.is-loading)::after {
  border-color: #664bf3;
  margin-top: -0.45em;
}

#browse-categories-select .select,
#browse-sort .select {
  height: auto;
}

#browse-search {
  width: 168px;
  max-width: 168px;
  display: inline-block;
  color: #424345;
  vertical-align: middle;
}

#browse-search input {
  color: #424345;
  border-radius: 8px;
  background-color: #2e3135;
  border: 1px solid #2e3135;
  padding-bottom: 0.7em;
}

#browse-search i {
  color: #5c6064;
}

#browse-search input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: #5c6064;
}

#browse-search input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #5c6064;
  opacity: 1;
}

#browse-search input::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #5c6064;
  opacity: 1;
}

#browse-search input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #5c6064;
}

#browse-search input::-ms-input-placeholder { /* Microsoft Edge */
  color: #5c6064;
}

#browse-search input::placeholder { /* Most modern browsers support this now. */
  color: #5c6064;
}


#browse-window {
  padding: 1em 0;
  overflow: hidden;
  /**height: calc(100% - 75px );
  max-height: calc(100% - 75px );*/
}

#browse-list {
  height: 99%;
  max-height: 99%;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  white-space: normal;
  padding: 0 22px !important;
  margin: 0;
}

#browse-list.has-carousel {
  height: calc(100% - 460px) !important;
  max-height: calc(100% - 460px) !important;
}

/*#browse-list .__vuescroll {*/
/*  height: 100%;*/
/*  max-height: 100%;*/
/*}*/
/*#browse-list .__panel {*/
/*  height: 100%;*/
/*  max-height: 100%;*/
/*}*/

/*#browse-list .__view {*/
/*  height: 100%;*/
/*  max-height: 100%;*/
/*}*/


#browse-load-more {
  width: 100%;
  text-align: center;
  margin: 1em 0 3em;
}

#browse-load-more button {
  background-color: #303538;
  border-color: #303538;
  color: #797979;
}

#browse-load-more-query {
  width: 100%;
  text-align: center;
  margin: 1em 0 3em;
}

#browse-load-more-query button {
  background-color: #303538;
  border-color: #303538;
  color: #797979;
}


#browse-heading {
  display: inline-block;
  width: 200px;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.67px;
  color: #ffffff;
}

#browse-actions {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  width: calc(100% - 200px);
  max-width: calc(100% - 200px);
  align-items: center;
  margin: auto;
}

.browse-action {
  display: flex;
  align-items: center;
}

#browse-broadcast .b-checkbox.checkbox:hover input[type="checkbox"]:not(:disabled) + .check {
  border-color: #664bf3;
  box-shadow: 0 0 0.5em rgba(0, 209, 178, 0);
}

#browse-broadcast .b-checkbox.checkbox input[type="checkbox"]:checked + .check {
  background-color: #664bf3;
  border-color: #664bf3;
  color: #FFFFFF;
}

#browse-broadcast .b-checkbox.checkbox input[type="checkbox"]:focus:checked + .check {
  box-shadow: 0 0 0.5em rgba(0, 209, 178, 0);
}



.dropdown-label {
  margin-right: 6px !important;
  color: #cccbcb;
  font-size: 14px;
  font-weight: 600;
  margin-top: 3px !important;
}

.dropdown-label span {

}


.display-table {
  display: table;
}



.main-scroll,
.has-scroll {
  overflow: auto;
  height: 100%;
  max-height: 100%;
}

.no-scroll {
  overflow: hidden;
  height: 100%;
  max-height: 100%;
}

.chat-scroll {
  padding: 0;
  margin: 0;
}


.browse-slider {
  position: relative;
  background-color: #171C22;
  padding-bottom: 40px;
}


/*** responsive
@media (max-width: 1440px)  {

}

@media (max-width: 1200px)  {

}

@media (max-width: 992px)  {

}


@media (max-width: 792px)  {

}

@media (max-width: 768px)  {

}

@media (max-width: 480px)  {

}

 */

</style>
